// stying for Attorney's Post/Pages

.attorneys-page tr {
    border-bottom: 1px #ccc solid;
    td {
        padding: 10px;
    }   
}
.attorneys-page tr:first-child {
    border-top: 1px #ccc solid;
}

footer.footer-post-nav {
    background-color: rgb(228, 228, 228);
    padding: 16px 20px;
    margin-bottom: 1em; 
    a {
        color: #222;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

h2 a {
    text-decoration: none;
    color: #222;
    &:hover {
        color-scheme: #888;
        text-decoration: underline;
    }
}

header.page-header h1 {
    font-size: 1.5em;
}

// Post comments
#comments h3 {
	text-align: center;
}

#comments h4 {
	font-size: 1.3em;
}

#comments ol {
	list-style: none;
}

#comments ol li {
	background: rgba(0,0,0,0.02);
	padding: 10px 20px;
	margin: 10px 0;
	-webkit-border-radius: 4px;
	moz-border-radius: 4px;
	border-radius: 4px;
	border: solid 1px rgba(0,0,0,0.03);
}

#comments ol li .children {
	padding: 0;
	list-style: none;
	font-size: 0.96em;
}

#comments .vcard {
    display: inline-flex;
    margin-bottom: 0;
    font-size: .80em;
    font-weight: bold;
}

#comments .vcard a {
    text-decoration: none;

}
.says {
    margin: -1px 0 0 1px!important;
}
.comment-form-cookies-consent {
    display: inline-flex;
    input {
        margin-right: .5em!important;
    }
}

#comments img.avatar {
    margin-right: 10px;
    border-radius: 50%;
}

#comments .vcard .url {
    margin-right: 4px;
}

#comments .comment-metadata {
    margin-top: -20px!important;
}

#comments .comment-metadata a {
    text-decoration: none;
    font-size: .80em;
    margin: -14px 40px 0;
}

#comments .comment-content {
    margin: 10px 0 0 40px;
}

.comment-reply-link {
    font-size: .80em;
    margin-left: 40px;
}


#leave-comment {
	margin: 40px 0 0;
	padding: 20px 0 0;
	border-top: solid 1px #eee;
}

// specific WordPress comment styles 
#comments footer {
	background: none;
	color: inherit;
}

#comments footer a {
	color: inherit;
}

#comments .comment-metadata {
	margin: 10px 0 0;
}

// Meta Data
.entry-meta {
    margin-top: -1em;
}

// Categories & Tags
.cat-links,
.tags-links {
    margin-right: 1em;
}
 

