#main-footer {
    background-color: #e7e4eb;
    padding: 80px 10px 0 10px;
    margin-bottom: -48px;
    a {
        color: #222;
        text-decoration: none;
    }
    a.developer {
        color: #7736d8;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

#main-footer li {
    list-style: none;
}

#main-footer ul.list-2-columns {
    list-style: none;
    padding: 0;
    color: #fff;
    column-count: 2;
    column-gap: -20px!important;

    a {
        color: #222;
        text-decoration: none;
    }
}

@media screen and ( max-width : 768px ) {
    .footer-menu ul {
        text-indent: -32px;
    }

    #main-footer ul.list-2-columns {
        column-count: 1;
    }
}