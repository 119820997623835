
// Serach entry
.navbar input.search-submit,
.modal-body input.search-submit {
    background: #1C7CD5 !important;
    &:hover {
        background: #0983f5 !important;
    }
    color: #fff !important;
    border: none !important;
    border-radius: 0 4px 4px 0 !important;
    padding: 6px 20px !important;
    margin-left: -5px !important;
    text-transform: uppercase !important;
    letter-spacing: .1em !important;
}

.search-form input.search-field {
	// display: none;
    border-radius: 4px 0 0 4px !important;
    padding-left: 10px !important;
}



#hero input,
#hero textarea {
    padding-left: 1em;
}
// #hero input {
//     opacity: 0.7;
// }

#hero ::placeholder {
    text-transform: uppercase;
    color: #666!important;
}
#hero button {
    text-transform: uppercase;
}

// Comment form
.comment-form label {
	display: block;
	font-weight: bold;
}

.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form input[type="password"],
.comment-form input[type="search"],
.comment-form textarea {
	color: #666;
	border: 1px solid #ccc;
	border-radius: 3px;
	display: block;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	padding: 6px 12px;
}

.comment-form input[type="submit"],
.comment-form button[type="submit"] {
	display: inline-block;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #fff;
	background-color: #000000;
	background-image: none;
	border: none;
	-webkit-border-radius: 6px;
	border-radius: 2px;
	padding: 6px 10px;
}

.comment-form input[type="submit"]:hover,
.comment-form button[type="submit"]:hover {
	outline: none;
	border: none;
	color: #000000;
	background: #e4e4e4;
}
