// Site wide CSS Style


.py-80 {padding: 5em 0;}

.banner-img {
    height: 500px;
    width: 100%;
}


// Image Size Reset
img {
    max-width: 100%!important;
    height: auto;
}

// Blog Page Image
.center-cropped{
    height: 240px;
    overflow: hidden;
  }
  
  .center-cropped img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }





