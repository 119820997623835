.widget-area section {
    border: 1px #ccc solid;
    padding: 10px;
    margin-bottom: 1em;
    ul {
        list-style: none;
        text-indent: -1.5em;
        a {
            color: #222;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
}

.widget-title {
    text-transform: uppercase;
}