// LEGALSHIELD SECTION
#legalshield {
    background: #e8cdfc;
    padding: 1.875em 0.625em 1em 0.625em;
    h2 {
        padding: 0 .5em 0 0;
    }
    a {
        color: #fff;
        text-transform: uppercase;
        margin-top: -5px;
    }
}

// REVIEWS SECTION
#reviews {
    background-color: #222;
    color: #fff;
}

// ATTORNIES SECTION
ul.post-categories {
    list-style: none;
    margin: 0;
    text-align: center;
    li {
        text-indent: -20px;
    }
    a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        color: #666;
        &:hover {
            text-decoration: underline;
        }
    }
}


ul.law-icon {
    list-style: none;
    padding: 0;
    color: #fff;
    column-count: 2;
    column-gap: 40px;

    li {
        padding-left: 1.3em;
        font-size: 1.3em;
        font-weight: 600;
        a {
            color: #fff;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
      }
      li:before {
        content: "\f24e"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin: 0 0.8em 0 -1.3em; /* -1.3em same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
      }
  }

  // Media Queries

  @media screen and ( max-width : 768px ) {
    ul.law-icon {
        column-count: 1;
    }
  }

  p.contact-address {
      font-size: 1.1em;
      line-height: 1;
      display: flex;
      align-items: center;
      span {
          padding-left: 1.3em;
          display: block;
      }
      &:before {
          content: "\f3c5";
          font-family: FontAwesome;
          font-size: 1.5em;
          margin-left: -1em;
      }
  }

  p.contact-phone {
    font-size: 1.1em;
    display: flex;
    align-items: center;
    span {
        padding-left: 0.8em;
        display: block;
        a {
            color: #222;
            text-decoration: none;
        }
    }
    &:before {
        content: "\f879";
        font-family: FontAwesome;
        font-size: 1.5em;
        margin-left: -1em;
    }
}

.contact-map {
    display: block;
    width: 100%;
    iframe {
        width: 100%;
    }
}

