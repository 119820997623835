// Typography Resets

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
    color: #222;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 2.3rem;
    margin-bottom: 0.875rem;
}

h2 {
    font-size: 2em;
    margin-bottom: 0.760em;
}

.entry-title {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
}

#about h1.entry-title {
    font-size: 1.75em!important;
}