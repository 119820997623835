//Mainheader

.main-header {
    position: sticky;
    top: -112px;
    z-index: 1005;
}

.main-header-top {
    background-color: #fff;
    a {
        color: #222!important;
        text-decoration: none;

    }
    a.telephone {
        font-weight: 600;
        color: #222222!important;
        margin-bottom: 1.2em!important;
        &:hover {
            color: #1d67d6;
        }
    }

}


// branding, logo
.navbar-brand {
    color: #222;
    text-transform: uppercase;
}
.navbar-brand h6 {
    font-family: 'Baskervville', serif;
    font-size: 1.5rem;
    margin-bottom: -1.75rem;
    padding: 0;
}
.navbar-brand {
    line-height: 1;
}
.navbar-brand small {
    font-size: .65em;
    text-transform: initial;
    text-align: center;
}

.custom-logo-link img {
    max-height: 6rem;
    width: auto;
    padding-left: 0px;
}



// Social Media
.main-header-top .social-icon i {
    font-size: 1.2em!important;
    margin-right: 0.3em;
}

// Contact
.contact-container {
    text-align: center;
    line-height: 1;
    span {
        font-size: 0.78em!important;
        font-weight: 600;
        color: #0A58CA;
        text-transform: uppercase;
        letter-spacing: 0.09375em;
    }
    a {
        font-size: 1.8em;
    }
}


@media only screen and (max-width: 810px) {
    .main-header {
        // position: sticky;
        top: -112px;
    }
}


@media only screen and (max-width: 500px) {
    .navbar-brand h6 {
        font-size: 1rem;
        margin-bottom: -1.8rem;
        padding: 0;
    }
    .navbar-brand {
        line-height: 1;
    }
    .navbar-brand small {
        font-size: .43333333em;
        text-transform: initial;
    }
    
    .custom-logo-link img {
        max-height: 3rem;
        width: auto;
        padding-left: 0px;
    }

    .custom-logo-link img {
        max-height: 1.8rem;
        width: auto;
        padding-left: 0px;
    }
  }

  @media only screen and (max-width: 360px) {
    .custom-logo-link img {
        max-height: 1.4rem!important;
        width: auto;
        padding-left: 0px;
    }

  }

  

