//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@include googleFont((
  "name": "Montserrat",
  "folder": "Montserrat",
  "files": "Montserrat",
  "weights": (100, 200, 300, 400, 500, 600, 700, 800, 900)
));

@include googleFont((
  "name": "Baskervville",
  "folder": "Baskervville",
  "files": "Baskervville",
  "weights": (300, 400, 700)
));