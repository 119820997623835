// Button styles

.button-nav {
    margin-left: 2em;
    background: #FFC107!important;
    a {
        color: #000!important;
        text-transform: uppercase;
    }
    &:hover {
        background: #FFCA2C!important;
    }
}

@media only screen and (max-width: 810px) {
    .button-nav {
        width: fit-content!important;
        padding-left: 10px!important;
        padding-right: 10px!important;
        margin-left: 0;
    }
}