#hero {

    h3 {
    font-size: 1.4em;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    }
    h2 {
        // text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    }
    
}



